import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { HREF_MINUT_HELP_HOME_PROFILES } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import IndoorClimateSvg from "src/ui/icons/indoor-climate.svg"
import NoiseMonitoringSvg from "src/ui/icons/noise-monitoring.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { PresetCard } from "./PresetCard"

export function MonitoringPresets() {
  const { t, tPlaceholder, langKeys } = useTranslate()

  const title = tPlaceholder("Monitoring presets")
  const description = tPlaceholder(
    `Monitoring presets contain pre-configured settings and can be applied to a
    subset of rental units.`
  )
  return (
    <MonitoringPresetsBox>
      <div>
        <MText variant="heading2" marginBottom={spacing.XS2}>
          {title}
        </MText>
        <MText variant="body" color="secondary">
          {description}{" "}
          <ExternalLink href={HREF_MINUT_HELP_HOME_PROFILES}>
            {t(langKeys.learn_more)}
          </ExternalLink>
        </MText>
      </div>

      <CardBox>
        <PresetCard
          icon={<NoiseMonitoringSvg width={24} height={24} />}
          title={tPlaceholder("Noise monitoring")}
          description={tPlaceholder(
            "Tweak your thresholds, quiet hours and noise alerts."
          )}
          target={Routes.SettingsPresetsNoise.location()}
        />

        <PresetCard
          icon={<IndoorClimateSvg width={24} height={24} />}
          title={tPlaceholder("Indoor climate")}
          description={tPlaceholder(
            "Edit your humidity and temperature thresholds."
          )}
          target={Routes.SettingsPresetsIndoorClimate.location()}
        />
      </CardBox>
    </MonitoringPresetsBox>
  )
}

const MonitoringPresetsBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  margin-top: ${spacing.XL};
  container-type: inline-size;
`

const CardBox = styled.div`
  display: grid;
  gap: ${spacing.M};

  @container (${breakpoint.mediumUp}) {
    grid-template-columns: 1fr 1fr;
  }
`
